<template>
    <div class="main-content bg-default " style="min-height: 100vh">
        <!-- Navbar -->
        <auth-nav class="navbar-top navbar-horizontal navbar-dark"
                  containerClasses="px-4 container"
                  expand>
            <router-link slot="brand" class="navbar-brand" to="/">
                <img src="img/brand/imagotipo-w&t-524x170.png"/>
            </router-link>

            <template v-slot="{closeMenu}">
                <!-- Collapse header -->
                <div class="navbar-collapse-header d-md-none">
                    <div class="row">
                        <div class="col-6 collapse-brand">
                            <router-link to="/">
                                <img src="img/brand/imagotipo-w&t-524x170.png">
                            </router-link>
                        </div>
                        <div class="col-6 collapse-close">
                            <button type="button"
                                    @click="closeMenu"
                                    class="navbar-toggler"
                                    aria-label="Toggle sidenav">
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Navbar items -->
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <router-link class="nav-link nav-link-icon" to="/registro">
                            <i class="ni ni-single-copy-04"></i>
                            <span class="nav-link-inner--text">registro</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link nav-link-icon" to="/login-traveler">
                            <i class="ni ni-world"></i>
                            <span class="nav-link-inner--text">Traveler</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link nav-link-icon" to="/login">
                            <i class="ni ni-single-02"></i>
                            <span class="nav-link-inner--text">Login</span>
                        </router-link>
                    </li>
                </ul>
            </template>
        </auth-nav>
        <!-- Header -->
        <div class="header  py-6 py-lg-7">
            <div class="container">
                <div class="header-body text-center mb-7">
                    <div class="row justify-content-center">
                        <div class="col-lg-5 col-md-6">
                            <h1 class="text-white">Bienvenido!</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div class="separator separator-bottom separator-skew zindex-100">
                <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
                     xmlns="http://www.w3.org/2000/svg" >
                    <!-- <polygon  points="2560 0 2560 100 0 100" fill="rgb(239,74,129)"></polygon> -->
                </svg>
            </div>
        </div>
        <!-- Page content -->
        <div class="container mt--8 pb-4" >
            <slide-y-up-transition mode="out-in" origin="center top">
                <router-view></router-view>
            </slide-y-up-transition>
        </div>
        <footer class="py-5">
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-12">
                        <div class="copyright text-center text-muted text-light">
                             <a href="https://www.aupairkids-tim.com.pe" class="font-weight-bold ml-1 text-secondary" 
                                               target="_blank">APK</a> &copy; {{year}} All rights reserved
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
  import { SlideYUpTransition } from 'vue2-transitions'

  export default {
    name: 'apk-auth',
    components: {
      SlideYUpTransition
    },
    data() {
      return {
        year: new Date().getFullYear(),
        showMenu: false
      }
    }
  }
</script>
<style>
</style>
